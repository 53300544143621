import React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout>
      <div className="my-10 space-y-10 text-center flex flex-col justify-items-center">
        <div>
          <h1 className="text-2xl font-bold mb-5">Öffnungszeiten</h1>
          <p>
            Täglich von 11:30 - 14:30
            <br></br>
            und 17:30 - 23:00
          </p>
          <p className="mt-4">Dienstag: Ruhetag</p>
        </div>
        <div className="">
          <h1 className="text-2xl font-bold mb-5">Kontakt Information</h1>
          <div className="space-y-4">
            <a
              className="underline space-y-0.5"
              href="https://goo.gl/maps/PZfvRrNhyoTvdQBH6"
            >
              Restaurant Delphi
              <br></br>
              Hochstr. 76
              <br></br>
              56112 Lahnstein
            </a>
            <p>Familie Kapsalis</p>
            <a className="underline" href="tel:0049262150606">
              +49 2621 50606
            </a>
            <br></br>
            <a className="underline" href="mailto:i.kapsalis@gmx.de">
              i.kapsalis@gmx.de
            </a>
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <h1 className="text-2xl font-bold mb-5">
            Veranstaltungen & Catering
          </h1>
          <p className="w-11/12 sm:w-4/5 lg:w-3/5 xl:w-2/5 m-auto">
            Wir Griechen wissen, wie man feiert. Damit wir davon etwas an Sie
            weitergeben können, richten wir gerne Ihre Feierlichkeiten aus.
            Falls sie wissen möchten was wir sonst noch für Sie tun können,
            rufen sie uns doch gerne an!
          </p>
        </div>
      </div>
    </Layout>
  )
}
